<template>
  <div>
    <suggestions-list suggestion-type="Organization" />
  </div>
</template>

<script>
import SuggestionsList from '@/common/components/OrganizationSuggestions/SuggestionsList.vue'

export default {
  components: { SuggestionsList },

}
</script>

<style>

</style>
